import React from "react";
import styled from "styled-components";

const TextBlock = ({ contents }: { contents: any }) => {
  return (
    <TextBlockWrap>
      <div className="inner">
        <div className="text-block" dangerouslySetInnerHTML={{__html: contents }} >
        </div>
      </div>
    </TextBlockWrap>
  )
}


export default TextBlock;

const TextBlockWrap = styled.div`
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;

  .inner {
    box-sizing: border-box;
  }


  @media screen and (min-width: ${({theme}) => theme.breakPoints.desktop}) {

    line-height: 1.6;
    font-size: 1.25vw;
    
    .inner {
      width: 90%;
      margin-left: 8vw;
      margin-right: 8vw;

      p {
        margin-top: 0;
      }
    }

    .text-block {
      column-count: 2;
      column-gap: 60px;
    }

  }

  @media screen and (min-width: ${({theme}) => theme.breakPoints.wide}) {
    .inner {
      width: 70vw;;
      margin-left: auto;
    }
  }
`

